<template>
  <div id="doc">
    <div v-if="documents && documents.length == 0">
      <p class="px-1">
        {{
          $t(
            "customer_information.information_content.detail_empty_attached_files"
          )
        }}
      </p>
    </div>
    <b-row v-else class="">
      <b-col
        v-for="(file, index) in documents"
        :key="index"
        cols="12"
        sm="12"
        :md="cols == false ? '6' : '4'"
        :lg="cols == false ? '6' : '4'"
      >
        <div
          class="row d-flex align-items-center justify-content-start mt-1 rw"
        >
          <b-col cols="1">
            <img
              v-if="checkFileExtension(file.file_name) == true"
              :src="documentImage"
              class="ms-2"
              width="26px"
              height="26px"
            />
            <img
              v-else
              :src="documentFile"
              class="ms-2"
              width="26px"
              height="26px"
            />
          </b-col>

          <b-col cols="11">
            <span v-b-tooltip.hover.top="file.file_name" class="ms-2 file">{{
              reduct(file.file_name, 20)
            }}</span
            ><br />
            <span class="ms-2 file"
              ><strong
                >{{
                  $t(
                    "customer_information.information_content.detail_file_size"
                  )
                }}: </strong
              >{{ formatSize(file.size) }}</span
            >
          </b-col>
          <b-col cols="12" class="p-0">
            <b-button
              v-if="is_demande == true"
              variant="primary"
              class="mt-1 mr-1 col-2"
              size="sm"
              @click="
                () => {
                  $emit('on_would_see_file', file);
                }
              "
            >
              <i v-if="!isDownloading" class="las la-eye la-lg" />
              <b-spinner v-else small /> &nbsp;
            </b-button>
            <b-button
              variant="primary"
              :href="file.original_url"
              :disabled="isDownloading"
              class="mt-1 col-10"
              size="sm"
              @click="downloadFile(file.file_name, file.original_url)"
            >
              <i v-if="!isDownloading" class="las la-file-download la-lg" />
              <b-spinner v-else small /> &nbsp; {{ $t("button.download") }}
            </b-button>
          </b-col>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTags,
  BSpinner,
  BBadge,
  BCardHeader,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTags,
    BSpinner,
    BBadge,
    BCardHeader,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    documents: {
      required: false,
      default: null,
      type: Array,
    },
    is_demande: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isDownloading: false,
      col: false,
    };
  },
  computed: {
    documentImage() {
      return require("@/assets/images/icons/img.png");
    },
    documentFile() {
      return require("@/assets/images/icons/docs.png");
    },
    cols() {
      const div = document.getElementById("doc");
      // console.log("divWidth", div);
      if (div != null) {
        const divWidth = div.offsetWidth;
        // console.log("divWidth", divWidth);
        if (divWidth > 800) {
          this.col = true;
        } else {
          this.col = false;
        }
        return this.col;
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {},
    },
  },

  methods: {
    formatSize(sizeInBytes) {
      const units = ["b", "kb", "mb", "gb"];
      let size = sizeInBytes;
      let unitIndex = 0;
      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }
      return `${size.toFixed(2)} ${units[unitIndex]}`;
    },
    checkFileExtension(event) {
      if (event) {
        const extension = event.split(".").pop().toLowerCase();
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"]; // Add more image extensions if needed
        return imageExtensions.includes(extension);
      }
    },
    reduct(chaine, nombreCaracteres) {
      if (chaine.length <= nombreCaracteres) {
        return chaine;
      }
      const extension = chaine.split(".").pop().toLowerCase();
      const premiersCaracteres = `${chaine.substring(
        0,
        nombreCaracteres
      )}...${extension}`;
      return premiersCaracteres;
    },

    async downloadFile(filename, fileUrl) {
      console.log("🔴🔴🔴response::: ");
      this.isDownloading = true;
      try {
        const response = await fetch(`${fileUrl}`);
        // console.log("🔴🔴🔴response::: ", response);
        const blob = await response.blob();
        // ------------------------------------
        // const custom_blob = await this.convertReadableStreamToBlob(
        //   response.body
        // );
        // console.log("custom_blob::: ", custom_blob);
        // ------------------------------------
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
        this.isDownloading = false;
      } catch (error) {
        console.log('error::: ', error);
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Une erreur s'est produite lors du téléchargement du fichier :" + error,
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
        this.isDownloading = false;
      }
      /* const link = document.createElement('a');
      link.href = fileUrl;
      //  link.target = '_blank';
      link.download = filename;

      // Simulate a click on the element <a>
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); */
    },
  },
};
</script>
<style scoped>
.rw {
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.file {
  font-size: 14px;
  color: #343a40;
}

.d-flex {
  white-space: nowrap;
}

img {
  border-radius: 5px;
  aspect-ratio: 16/9;
}
</style>
